import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const Members = [
  {
      full_name: 'Joshua Herrera',
      role: 'Co-CEO, EE Team Lead',
      img: require('../../assets/members/placeholder.png')
  },
  {
      full_name: 'Diane Heinle',
      role: 'Co-CEO, MechE Team Lead',
      img: require('../../assets/members/placeholder.png')
  },
  {
      full_name: 'Christian Camacho',
      role: 'MechE Team Lead',
      img: require('../../assets/members/placeholder.png')
  },
  {
      full_name: 'Timber Carey',
      role: 'MechE Team Lead',
      img: require('../../assets/members/placeholder.png')
  },
  {
      full_name: 'Ricardo Ruiz',
      role: 'CS Team Lead',
      img: require('../../assets/members/placeholder.png')
  },
  {
      full_name: 'Christian Teshome',
      role: '',
      img: require('../../assets/members/placeholder.png')
  },
  {
      full_name: 'Audrey Lee',
      role: 'EE Team Lead',
      img: require('../../assets/members/placeholder.png')
  },
  {
      full_name: 'Kenneth Muhammad',
      role: 'EE Team Lead',
      img: require('../../assets/members/placeholder.png')
  },
  {
      full_name: 'Jennet Zamanova',
      role: '',
      img: require('../../assets/members/placeholder.png')
  },
  {
      full_name: 'Cesia Massott',
      role: '',
      img: require('../../assets/members/placeholder.png')
  },
  {
      full_name: 'Antonio Avila',
      role: '',
      img: require('../../assets/members/placeholder.png')
  },
  {
      full_name: 'Christine Xu',
      role: '',
      img: require('../../assets/members/placeholder.png')
  },
  {
      full_name: 'Kent Brought',
      role: '',
      img: require('../../assets/members/placeholder.png')
  },
  {
      full_name: 'Ruchitha Channapatna',
      role: '',
      img: require('../../assets/members/placeholder.png')
  },
  {
      full_name: 'Olivia Munoz',
      role: '',
      img: require('../../assets/members/placeholder.png')
  },
  {
      full_name: 'An Bo Chen',
      role: '',
      img: require('../../assets/members/placeholder.png')
  },
  {
      full_name: 'Rodis Aguilar',
      role: '',
      img: require('../../assets/members/placeholder.png')
  },
  {
      full_name: 'Diego Rodriguez',
      role: '',
      img: require('../../assets/members/placeholder.png')
  },
  {
      full_name: 'Summer Warren',
      role: '',
      img: require('../../assets/members/placeholder.png')
  },
  {
      full_name: 'Darren Chen',
      role: '',
      img: require('../../assets/members/placeholder.png')
  },
  {
      full_name: 'Eyan Forsythe',
      role: '',
      img: require('../../assets/members/placeholder.png')
  },
  {
      full_name: 'Sebastian Becerril Salinas',
      role: '',
      img: require('../../assets/members/placeholder.png')
  },
  {
      full_name: 'Haydn Long',
      role: '',
      img: require('../../assets/members/placeholder.png')
  }
];

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const BioTile = ({img, bio, name, role}) => {
  return (
    
      <div className="tiles-item-inner">
        <div className="testimonial-item-image mb-16">
            <Image
              src={img}
              alt="Features tile icon 01"
              width={180}
              height={180} />
        </div>
        <div className="testimonial-item-content">
          <p className="text-sm mb-0">
            {bio}
          </p>
        </div>
        <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
          <span className="testimonial-item-name text-color-high">{name}</span>
          <span className="testimonial-item-role">
            <p>{role}</p>
          </span>
        </div>
      </div>

  );
};

const BioTileLeft = ({img, bio, name, role}) => {
  return (
    <div className="tiles-item reveal-from-right" data-reveal-delay="200">
      <BioTile img={img} bio={bio} name={name} role={role} />
    </div>
  );
};

const BioTileCenter = ({img, bio, name, role}) => {
  return (
    <div className="tiles-item reveal-from-bottom">
      <BioTile img={img} bio={bio} name={name} role={role} />
    </div>
  );
};

const BioTileRight = ({img, bio, name, role}) => {
  return (
    <div className="tiles-item reveal-from-left" data-reveal-delay="200">
      <BioTile img={img} bio={bio} name={name} role={role} />
    </div>
  );
};

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Members',
  };

  let tiles = [];
  Members.sort(function(a, b) {
    let name_a = a.full_name,
      name_b = b.full_name;
    if (name_a < name_b) return -1;
    if (name_b > name_a) return 1;
    return 0;
  });

  for (let i = 0; i < Members.length; i = i + 1) {
    let Tile = i % 3 === 0
      ? BioTileLeft
      : i % 3 === 1
      ? BioTileCenter
      : BioTileRight;

    tiles.push(<Tile
      key = {i}
      img = {Members[i].img}
      bio = ''
      name = {Members[i].full_name}
      role = {Members[i].role}
    />);
  }
  

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            {tiles}
          </div>
        </div>
      </div>
    </section>
  );
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;