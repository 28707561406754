import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              Design. Build. <span className="text-color-primary">Repeat.</span>
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                MIT's Premier Competitive Robotics Team
              </p>
            </div>
          </div>
          <div className="hero-content">
            The MIT Robotics Team is a group of highly motivated undergraduate students from the Massachusetts Institute of Technology. Our diverse group, with majors ranging from Mechanical Engineering to Business Analytics, shares a common passion for the field of robotics. The team’s purpose is to explore exciting new technologies, learn critical skills, and promote the field of robotics through entering a variety of competitions and engaging in outreach events in the wider community. Unlike many other student teams, the Robotics Team is not formed around any one particular contest or event. Rather, the team prefers to continually evolve through finding exciting new opportunities where they can use the skills they have developed from previous competitions.
          </div>
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;