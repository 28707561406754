import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import Testimonial from '../components/sections/Testimonial';

const Home = () => {

  return (
    <>
      <Hero className="illustration-section-01" id='about'/>
      <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" id='projects'/>
      <FeaturesTiles topDivider id='sponsors'/>
      <Testimonial topDivider id='members'/>
    </>
  );
}

export default Home;